import { allPass, always, cond, pathOr, pipe, propOr, T } from 'ramda'
import { notEqual } from 'ramda-adjunct'
import { isValidJSON } from 'src/utils'

import { CONFIRMATION_EXIT_MESSAGE } from './constants'

export const alertUser = (event: BeforeUnloadEvent): string => {
  // show exit alert during page unload
  event.preventDefault()
  event.returnValue = CONFIRMATION_EXIT_MESSAGE
  return CONFIRMATION_EXIT_MESSAGE
}

export const confirmExitHandler = (event: PopStateEvent, setIsBackButtonClicked: any): void => {
  // remove beforeunload during popState event, not to have collision between those two
  window.removeEventListener('beforeunload', alertUser)

  // show exit alert
  const leaveThisPage = window.confirm(CONFIRMATION_EXIT_MESSAGE)

  if (leaveThisPage) {
    // go back when user want to leave page and remove popState event to avoid loop of alerts
    window.history.back()
    window.onpopstate = null
  }
  if (!leaveThisPage) {
    // not changing URL, change state of isBackButtonClicked to retrigger useEffect of useDetailsChanged and remove popState event to avoid loop of alerts
    setIsBackButtonClicked((prevState: boolean) => !prevState)
    window.onpopstate = null
  }
}

export const getRefetchInterval = (status: string, milliSeconds: number): number | false =>
  status === 'error' ? false : milliSeconds

export const retryIfErrorIsNotConnectionOrDistributionGroup = (error: {
  request: { response: string }
}): boolean => {
  return pipe(
    pathOr('', ['request', 'response']),
    cond([
      [
        isValidJSON,
        pipe(
          JSON.parse,
          propOr(400, 'statusCode'),
          allPass([notEqual(404), notEqual(406), notEqual(403), notEqual(400)])
        ),
      ],
      [T, always(true)],
    ])
  )(error)
}
