// @ts-expect-error
export function hotJarFun(h, o, t, j, a, hotjarId, r?): void {
  h.hj = (h.hj as boolean)
    ? h.hj
    : function () {
        ;(h.hj.q = (h.hj.q as boolean) ? h.hj.q : []).push(arguments)
      }
  h._hjSettings = { hjid: hotjarId, hjsv: 6 }
  r.src = `${t as string}${h._hjSettings.hjid as string}${j as string}${
    h._hjSettings.hjsv as string
  }`
  a.appendChild(r)
}
