import { useQuery, UseQueryResult } from 'react-query'
import { ApiError } from 'src/routes/Investor/EditOrder/hooks'
import {
  getRefetchInterval,
  retryIfErrorIsNotConnectionOrDistributionGroup,
} from 'src/routes/Investor/EditOrder/utils'

import { getUserProfile, ProfileDTO } from '../../services/users'
import { ReactQueryKey } from '../types'

export function useGetUserProfile(): UseQueryResult<ProfileDTO, ApiError> {
  return useQuery<ProfileDTO, ApiError>([ReactQueryKey.USER_PROFILE], getUserProfile, {
    cacheTime: 0,
    notifyOnChangeProps: ['data', 'error'],
    refetchIntervalInBackground: true,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchInterval: (_, query) => getRefetchInterval(query.state.status, 10000),
    retry: (_, error) => retryIfErrorIsNotConnectionOrDistributionGroup(error),
  })
}

export function useGetUserProfileForRequestAccessPage(): UseQueryResult<ProfileDTO, ApiError> {
  return useQuery<ProfileDTO, ApiError>(
    [ReactQueryKey.USER_PROFILE, 'Request access page'],
    getUserProfile,
    {
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      notifyOnChangeProps: ['data', 'error'],
      refetchIntervalInBackground: false,
      retry: false,
    }
  )
}
