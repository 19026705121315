import { DealSetupCurrency } from 'components/Orders/Demands/types'
import { Function as F, Option as Opt, ReadonlyArray as RA } from 'effect'
import { DateTime } from 'luxon'
import {
  always,
  cond,
  converge,
  curry,
  defaultTo,
  equals,
  find,
  gt,
  head,
  identity,
  ifElse,
  last,
  map,
  pickBy,
  pipe,
  prop,
  propEq,
  T,
} from 'ramda'
import { isNotNil, isTrue } from 'ramda-adjunct'
import { type Row } from 'react-table'
import { ColumnType } from 'src/lib/types'
import { type MloSetupDto } from 'src/routes/Investor/EditOrder/mlo-setup.types'
import { type Currency } from 'src/store/commonTypes'

import { FeatureFlagsDto } from './queries/useGetFeatureFlags'

// example: 18-Oct-2022 15:43 UTC
export const getFormattedDateEnteredBy = (val: string): string => {
  return `${DateTime.fromJSDate(new Date(val)).toUTC().toFormat('dd-LLL-y, HH:mm')}`
}

// example: 30 Aug 2022
export const getFormattedDate = (value: string): string =>
  DateTime.fromJSDate(new Date(value)).toUTC().toFormat('d LLL y')

export const getValidCurrency = (mloSetup: MloSetupDto): Opt.Option<Currency> =>
  F.pipe(
    Opt.fromNullable(mloSetup.currency),
    Opt.flatMapNullable(
      F.flow(
        RA.findFirst((currency: DealSetupCurrency) => currency.acceptOrderSubmission),
        Opt.flatMap(F.flow(prop('currencyName'), Opt.fromNullable))
      )
    ),
    Opt.flatten
  )

export const getCurrencyValue = (mloSetup: MloSetupDto): string =>
  F.pipe(
    mloSetup,
    getValidCurrency,
    Opt.match({ onNone: F.constant('' as Currency), onSome: F.identity })
  )

export const removeUndefined = <T>(object: T): T => JSON.parse(JSON.stringify(object))

export const sortColumn = curry(
  (columnType: ColumnType, columnName: string, rowA: Row, rowB: Row) =>
    F.pipe(
      [rowA, rowB],
      map((row: Row) =>
        cond([
          [
            equals(ColumnType.DATE_ISO),
            always(
              ifElse(
                isNaN,
                always(0),
                identity
              )(DateTime.fromISO(row.values?.[columnName]).toMillis())
            ),
          ],
          [equals(ColumnType.TEXT), always(row.values?.[columnName]?.toLowerCase())],
          [T, always(row.values?.[columnName])],
        ])(columnType)
      ),
      ifElse(
        converge(curry(equals), [head, last]),
        always(identity),
        ifElse(converge(curry(gt), [head, last]), always(1), always(-1))
      )
    )
)

export const isAppEnabled = (enabledApplications: string[] | undefined): boolean =>
  enabledApplications?.map((appName) => appName.toLowerCase()).includes('ioi') ?? false

export const skipNullish = pickBy((val, key) => isNotNil(val))

export const isFeatureFlagEnabled = (
  featureFlagName: string,
  featureFlags?: FeatureFlagsDto[]
): boolean =>
  pipe(
    defaultTo([]),
    find(propEq('name', featureFlagName)),
    prop('enabled'),
    defaultTo(false),
    isTrue
  )(featureFlags)
