import React, { ReactNode } from 'react'

import { AppContainerFullWidth } from './AppContainerFullWidth'
import { AppContainerWithMargins } from './AppContainerWithMargins'

interface Props {
  children: ReactNode
  isAuthenticated: boolean
  fullWidth?: boolean
}

export function AppContainer({ children, isAuthenticated, fullWidth = false }: Props): JSX.Element {
  return fullWidth ? (
    <AppContainerFullWidth isAuthenticated={isAuthenticated}>
      <div>{children}</div>
    </AppContainerFullWidth>
  ) : (
    <AppContainerWithMargins isAuthenticated={isAuthenticated}>
      <div>{children}</div>
    </AppContainerWithMargins>
  )
}
